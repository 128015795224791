import React, { useState } from 'react';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import CadastrarUsuario from './components/screens/CadastroUsuarioPanel';
import PermissaoUsuario from './components/screens/PermissaoUsuarioPanel';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
    // Estado para controlar se o usuário está autenticado (pegando do localStorage)
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
    const [empresaName, setEmpresaName] = useState(localStorage.getItem('empresaName') || ''); // Nome da empresa
    const [userId, setUserId] = useState(localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId'), 10) : null); // ID do usuário logado
    const [userType, setUserType] = useState(localStorage.getItem('userType') || ''); // Tipo do usuário
    const [userName, setUserName] = useState(localStorage.getItem('userName') || ''); // Nome do usuário

    // Função que será passada para o componente Login para lidar com a autenticação
    const handleLogin = (userId, userName, empresaName, tipo) => {
        setIsAuthenticated(true); // Atualiza o estado para autenticação
        setEmpresaName(empresaName); // Atualiza o nome da empresa ao logar
        setUserId(userId); // Armazena o ID do usuário
        setUserType(tipo); // Define o tipo de usuário logado
        setUserName(userName); // Armazena o nome do usuário

        // Armazena as informações no localStorage
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('empresaName', empresaName);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userType', tipo);
        localStorage.setItem('userName', userName);
    };

    // Função de logoff
    const handleLogoff = () => {
        setIsAuthenticated(false);
        setEmpresaName('');
        setUserId(null);
        setUserType('');
        setUserName('');

        // Remove as informações do localStorage
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('empresaName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('userName');
    };

    // Função que protege rotas
    const ProtectedRoute = ({ element }) => {
        return isAuthenticated ? element : <Navigate to="/" />; // Se autenticado, renderiza o componente, caso contrário redireciona para o login
    };

    return (
        <Router>
            <div className="App">
                {/* Definindo as rotas da aplicação */}
                <Routes>
                    {/* Página principal: renderiza o Dashboard se autenticado, senão redireciona para o Login */}
                    <Route
                        path="/"
                        element={
                            isAuthenticated ? (
                                <Dashboard 
                                    empresaName={empresaName} 
                                    userId={userId} 
                                    userType={userType}
                                    userName={userName}
                                    onLogoff={handleLogoff}  // Adiciona a função de logoff ao Dashboard
                                />
                            ) : (
                                <Login onLogin={handleLogin} />
                            )
                        }
                    />
                    {/* Rota direta para o Dashboard, protegida */}
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute
                                element={<Dashboard 
                                    empresaName={empresaName} 
                                    userId={userId} 
                                    userType={userType}
                                    userName={userName} 
                                    onLogoff={handleLogoff}  // Adiciona a função de logoff ao Dashboard
                                />}
                            />
                        }
                    />
                    {/* Rota para o cadastro de usuário, também protegida */}
                    <Route
                        path="/cadastrousuario"
                        element={
                            <ProtectedRoute
                                element={<CadastrarUsuario />}
                            />
                        }
                    />
                    {/* Rota para o cadastro de permissões de usuários, também protegida */}
                    <Route
                        path="/permissaousuario"
                        element={
                            <ProtectedRoute
                                element={<PermissaoUsuario />}
                            />
                        }
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
