import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import api from '../../services/api';

function CadastroUsuarioPanel() {
    const [usuarios, setUsuarios] = useState([]);  // Lista de usuários
    const [selectedUser, setSelectedUser] = useState('Novo Usuário');  // Usuário selecionado (Novo por padrão)
    const [username, setUsername] = useState('');  // Nome do usuário
    const [email, setEmail] = useState('');  // Email do usuário
    const [password, setPassword] = useState('');  // Senha do usuário (somente para novos cadastros)
    const [tipo, setTipo] = useState('');  // Tipo do usuário
    const [message, setMessage] = useState('');

    // Função para buscar todos os usuários cadastrados
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.getClients();  // Chama a função para buscar usuários
                const sortedUsers = response.clients.sort((a, b) => a.name.localeCompare(b.name));  // Ordena os usuários pelo nome
                setUsuarios(sortedUsers);  // Define a lista de usuários ordenada
            } catch (error) {
                console.error('Erro ao buscar usuários:', error);
            }
        };
        fetchUsers();
    }, []);

    // Função que atualiza os campos quando um usuário é selecionado
    useEffect(() => {
        const fetchUserDetails = async () => {
            if (selectedUser !== 'Novo Usuário') {
                try {
                    const response = await api.getUserDetails(selectedUser);  // Chama a função que busca os detalhes do usuário
                    const { name, email, tipo } = response.data;  // Desestrutura os dados recebidos
                    setUsername(name);
                    setEmail(email);
                    setTipo(tipo);
                    setPassword('');  // Limpa o campo de senha
                } catch (error) {
                    console.error('Erro ao buscar detalhes do usuário:', error);
                }
            } else {
                // Limpa os campos se "Novo Usuário" for selecionado
                setUsername('');
                setEmail('');
                setPassword('');
                setTipo('');
            }
        };
        fetchUserDetails();
    }, [selectedUser]);

    // Função para cadastrar ou editar usuário
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedUser === 'Novo Usuário') {
            // Cadastro de novo usuário
            try {
                const response = await api.cadastrarUsuario({ username, email, password, tipo });
                setMessage(response.data.message);
            } catch (error) {
                setMessage(error.response ? error.response.data.message : 'Erro ao cadastrar usuário.');
            }
        } else {
            // Edição de usuário existente
            try {
                const response = await api.editarUsuario({ userId: selectedUser, username, email, tipo });
                setMessage(response.data.message);
            } catch (error) {
                setMessage(error.response ? error.response.data.message : 'Erro ao editar usuário.');
            }
        }
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5" style={{ backgroundColor: '#001d6b', color: 'white' }}>Cadastro de Usuário</Card.Header>
            <Card.Body>
                {message && <p className="text-center">{message}</p>}
                {/* Combobox para selecionar o usuário */}
                <Form.Group controlId="userSelect" className="mb-3">
                    <Form.Label>Selecione o Usuário</Form.Label>
                    <Form.Control as="select" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                        <option value="Novo Usuário">Novo Usuário</option>
                        {usuarios.map(user => (
                            <option key={user.id} value={user.id}>{user.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="username" className="mb-3">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome do usuário"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email do usuário"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>

                    {selectedUser === 'Novo Usuário' && (
                        <Form.Group controlId="password" className="mb-3">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                    )}

                    <Form.Group controlId="tipo" className="mb-3">
                        <Form.Label>Tipo</Form.Label>
                        <Form.Control 
                            as="select" 
                            value={tipo} 
                            onChange={(e) => setTipo(e.target.value)} 
                            required
                        >
                            <option value="">Selecione o tipo de usuário</option>
                            <option value="AG">ADMIN GERAL</option>
                            <option value="AS">ADMIN</option>
                            <option value="SU">SUPERVISOR</option>
                            <option value="US">USUÁRIO</option>
                        </Form.Control>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                        {selectedUser === 'Novo Usuário' ? 'Cadastrar' : 'Editar'}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default CadastroUsuarioPanel;
