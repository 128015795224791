import React from 'react';
import { Dropdown } from 'react-bootstrap';

function TopMenu({ onLogoff }) {
    return (
        <div className="top-menu" style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ color: 'black' }}>
                    <i className="fas fa-bars fa-2x"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={onLogoff}>Logoff</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default TopMenu;
