import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/logov.png'; // Caminho correto baseado na estrutura de pastas
import axios from 'axios'; // Importando o axios para fazer chamadas HTTP

function Login({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Adiciona um estado de carregamento

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(''); // Limpa o erro ao tentar o login
        setLoading(true); // Define o estado de carregamento para true

        try {
            // const response = await axios.post('http://127.0.0.1:5000/api/login', 
            const response = await axios.post('http://matrix.g-open.com.br/api/login',                 
                
            { 
                email, 
                password 
            }, {
                headers: {
                    'Content-Type': 'application/json' // Garantir que o Content-Type esteja correto
                }
            });

            const { data } = response;

            console.log('Login realizado com sucesso:', data);  // Para verificar a resposta do login

            // Chamar a função de callback do login bem-sucedido, passando o nome da empresa e o tipo de usuário
            onLogin(data.user_id, data.username, data.empresa_name, data.tipo); // Certifique-se de incluir o 'data.tipo'

        } catch (error) {
            setLoading(false); // Para encerrar o estado de carregamento

            if (error.response && error.response.status === 401) {
                setError('Senha incorreta');
            } else if (error.response && error.response.status === 404) {
                setError('Usuário ou empresa não encontrados');
            } else {
                setError('Erro ao tentar fazer login');
            }
        }
    };

    return (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Row>
                <Col>
                    <Card className="shadow p-3 mb-5 bg-white rounded" style={{ width: '350px' }}>
                        <Card.Body>
                            <div className="text-center mb-4">
                                <Image src={logo} alt="Logotipo" fluid style={{ width: '150px' }} />
                            </div>                            
                            <Card.Title className="text-center mb-4">Login</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Enter email"
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)} 
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPassword" className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Enter password"
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)} 
                                        required
                                    />
                                </Form.Group>

                                {/* Exibe mensagem de erro, se houver */}
                                {error && <p className="text-danger">{error}</p>}

                                {/* Desabilita o botão de login se estiver carregando */}
                                <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                                    {loading ? 'Carregando...' : 'Login'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
