import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../services/api';

function VendaSupervisor() {
    const [dataInicio, setDataInicio] = useState(''); // Data inicial
    const [dataFim, setDataFim] = useState(''); // Data final
    const [supervisor, setSupervisor] = useState(''); // Supervisor
    const [representante, setRepresentante] = useState(''); // Representante
    const [supervisores, setSupervisores] = useState([]); // Lista de supervisores
    const [representantes, setRepresentantes] = useState([]); // Lista de representantes
    const [graphUrl, setGraphUrl] = useState(null); // URL do gráfico
    const [totalKg, setTotalKg] = useState(0); // Total de KG
    const [filtro, setFiltro] = useState('mes'); // Filtro (mês, representante, todos representantes)

    useEffect(() => {
        // Carregar supervisores do backend e ordenar alfabeticamente, ignorando nulos
        api.getSupervisores().then(data => {
            const sortedSupervisores = data.supervisores
                .filter(s => s !== null)  // Ignorar valores nulos
                .sort((a, b) => a.localeCompare(b)); // Ordenar alfabeticamente
            setSupervisores(sortedSupervisores);
        });
    }, []);

    // Função para buscar os representantes quando o supervisor é selecionado
    useEffect(() => {
        if (supervisor) {
            api.getRepresentantesPorSupervisor(supervisor).then(data => {
                const sortedRepresentantes = data.representantes
                    .filter(r => r !== null)
                    .sort((a, b) => a.localeCompare(b));
                setRepresentantes(sortedRepresentantes);
            }).catch(error => {
                console.error("Erro ao carregar representantes:", error);
            });
        } else {
            setRepresentantes([]);  // Limpa a lista de representantes se o supervisor for removido
        }
    }, [supervisor]);

    // Função para lidar com a busca de vendas
    const handleSearch = () => {
        if (!dataInicio || !dataFim) {
            alert("Por favor, selecione um período válido.");
            return;
        }

        // Decide qual API chamar com base no filtro selecionado
        let apiCall;

        if (filtro === 'mes') {
            apiCall = api.getVendasPorPeriodo;
        } else if (filtro === 'representante') {
            apiCall = api.getVendasPorRepresentante;
        } else if (filtro === 'todos_representantes') {
            apiCall = api.getVendasPorTodosRepresentantes; // Novo endpoint que será criado
        }

        apiCall(dataInicio, dataFim, supervisor, representante)
            .then(response => {
                setTotalKg(response.total_kg || response.totalKg);
                setGraphUrl(response.graph_url || response.graphUrl);
            })
            .catch(error => {
                console.error("Erro ao buscar vendas: ", error);
            });
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5" style={{ backgroundColor: '#001d6b', color: 'white' }}>
                Venda por Supervisor
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicio}
                                onChange={e => setDataInicio(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="formDataFim">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFim}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {filtro === 'representante' && supervisor && (
                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group controlId="formRepresentante">
                                <Form.Label>Representante</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={representante}
                                    onChange={e => setRepresentante(e.target.value)}
                                >
                                    <option value="">Selecione o Representante</option>
                                    {representantes.map(r => (
                                        <option key={r} value={r}>{r}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Check
                            type="radio"
                            id="mes"
                            name="filtro"
                            label="Mês a Mês"
                            value="mes"
                            checked={filtro === 'mes'}
                            onChange={e => setFiltro(e.target.value)}
                        />
                    </Col>
                    <Col md={4}>
                        <Form.Check
                            type="radio"
                            id="representante"
                            name="filtro"
                            label="Por Representante"
                            value="representante"
                            checked={filtro === 'representante'}
                            onChange={e => setFiltro(e.target.value)}
                        />
                    </Col>
                    <Col md={4}>
                        <Form.Check
                            type="radio"
                            id="todos_representantes"
                            name="filtro"
                            label="Todos Representantes"
                            value="todos_representantes"
                            checked={filtro === 'todos_representantes'}
                            onChange={e => setFiltro(e.target.value)}
                        />
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSearch}>
                    Buscar Vendas
                </Button>

                {graphUrl && (
                    <div className="mt-4 text-center">
                        <h5>Total de KG: {totalKg}</h5>
                        <img src={`data:image/png;base64,${graphUrl}`} alt="Gráfico de Vendas" className="img-fluid mx-auto d-block"/>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}

export default VendaSupervisor;
