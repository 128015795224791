import React from 'react';

function IndicatorPanel({ indicator }) {
    if (!indicator) {
        return <p>Nenhum indicador selecionado.</p>;
    }

    return (
        <div>
            <h2>{indicator.name}</h2>
            <p>Descrição: {indicator.descricao}</p>
            {/* Adicione mais detalhes do indicador conforme necessário */}
        </div>
    );
}

export default IndicatorPanel;

