import axios from 'axios';

// const API_URL = 'http://localhost:5000'; // URL base da API
const API_URL = 'http://matrix.g-open.com.br'; // URL base da API

// Cria uma instância de axios com a URL base configurada
const api = axios.create({
    baseURL: `${API_URL}/api`,
});

// Função para tratar erros de requisição de forma mais elegante
const handleApiError = (error) => {
    console.error('API request failed:', error);
    throw error; // Retorna o erro para que o componente que chamou a função saiba que algo deu errado
};

// Função para cadastrar usuário
api.cadastrarUsuario = async (dadosUsuario) => {
    try {
        const response = await api.post('/cadastrarUsuario', dadosUsuario);
        return response;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para cadastrar permissões de acesso (Acesso_Usuario_Indicador)
api.cadastrarPermissao = async (dadosPermissao) => {
    try {
        const response = await api.post('/cadastrarPermissao', dadosPermissao);
        return response;
    } catch (error) {
        console.error('Erro ao cadastrar permissões:', error);
        throw error;
    }
};

// Função para atualizar permissões de indicadores
api.updatePermissions = async (data) => {
    try {
        const response = await api.post('/updatePermissions', data);
        return response;
    } catch (error) {
        console.error('Erro ao atualizar permissões:', error);
        throw error;
    }
};

api.getAllIndicators = async () => {
    try {
        const response = await api.get('/allIndicators');
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar todos os indicadores:', error);
        throw error;
    }
};

// Função para buscar os indicadores habilitados de um usuário
api.getEnabledIndicatorsByUser = async (userId) => {
    try {
        const response = await api.get(`/enabledIndicators/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar indicadores habilitados:', error);
        throw error;
    }
};

// Função para buscar clientes
api.getClients = async () => {
    try {
        const response = await api.get('/clients');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar indicadores de um cliente específico
api.getIndicatorsByClient = async (clientId) => {
    try {
        const response = await api.get(`/clients/${clientId}/indicators`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para obter faturamento com filtros
api.getFaturamento = async (filters) => {
    try {
        const response = await api.get('/indicadores/faturamento', { params: filters });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar supervisores
api.getSupervisores = async () => {
    try {
        const response = await api.get('/supervisores');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar representantes
api.getRepresentantes = async () => {
    try {
        const response = await api.get('/representantes');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar safras
api.getSafras = async () => {
    try {
        const response = await api.get('/safras');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar indicadores de um usuário específico
api.getIndicatorsByUser = async (userId) => {
    try {
        const response = await api.get(`/users/${userId}/indicators`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar os detalhes de um usuário
api.getUserDetails = async (userId) => {
    try {
        const response = await api.get(`/userDetails/${userId}`);
        return response;
    } catch (error) {
        console.error('Erro ao buscar detalhes do usuário:', error);
        throw error;
    }
};

// Função para editar um usuário existente
api.editarUsuario = async (dadosUsuario) => {
    try {
        const response = await api.put(`/editarUsuario/${dadosUsuario.userId}`, dadosUsuario);
        return response;
    } catch (error) {
        console.error('Erro ao editar usuário:', error);
        throw error;
    }
};

// Função para buscar os vendedores e ticket médio de acordo com a safra e supervisor
api.getVendedoresTicketMedio = async (filters) => {
    try {
        const response = await api.get('/vendedores_ticket_medio', { params: filters });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar dados de vendedores e ticket médio:', error);
        throw error;
    }
};

// Função para buscar vendas por período e supervisor
api.getVendasPorPeriodo = async (dataInicio, dataFim, supervisor, representante) => {
    try {
        const response = await api.get('/vendas-por-periodo', {
            params: {
                dataInicio: dataInicio,
                dataFim: dataFim,
                supervisor: supervisor,
                representante: representante
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar vendas por período:', error);
        throw error;
    }
};

api.getRepresentantesPorSupervisor = async (supervisor) => {
    try {
        const response = await api.get(`/representantes/${supervisor}`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar representantes: ", error);
        throw error;
    }
};

export default api;

// Função para buscar vendas por representante
api.getVendasPorRepresentante = async (dataInicio, dataFim, supervisor, representante) => {
    try {
        const response = await api.get('/vendas-por-representante', {
            params: {
                dataInicio: dataInicio,
                dataFim: dataFim,
                supervisor: supervisor,
                representante: representante
            }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar vendas por representante: ", error);
        throw error;
    }
};

api.getVendasPorTodosRepresentantes = async (dataInicio, dataFim, supervisor) => {
    try {
        const response = await api.get('/vendas-por-todos-representantes', {
            params: {
                dataInicio: dataInicio,
                dataFim: dataFim,
                supervisor: supervisor
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar vendas por todos os representantes:', error);
        throw error;
    }
};

