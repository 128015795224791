import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../services/api';

function IndiceVendaAbaixoTabela() {
    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5" style={{ backgroundColor: '#001d6b', color: 'white' }}>Índice de Vendas Abaixo da Tabela</Card.Header>
            <Card.Body>
                <Row className="mb-3">

                </Row>

            </Card.Body>
        </Card>
    );
}

export default IndiceVendaAbaixoTabela;
