import React from 'react';
import { ListGroup, Card } from 'react-bootstrap'; 

function Sidebar({ indicators = [], onSelectIndicator, onSelectScreen, userType }) {
    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5" style={{ backgroundColor: '#001d6b', color: 'white' }}>Menu - Dashboard</Card.Header>
            <Card.Body> 
            {(userType === 'AG' || userType === 'AS') && (
                    <>
                        <h6 className="mt-4">Cadastros</h6> 

                        <ListGroup variant="flush">
                            <ListGroup.Item 
                                action 
                                onClick={() => {
                                    console.log('Tela de permissão de usuário selecionada');  // Verifica se o clique está funcionando
                                    onSelectScreen('permissaousuariopanel');
                                }}
                            >
                                Permissões
                            </ListGroup.Item>
                        </ListGroup>        

                        <ListGroup variant="flush">
                            <ListGroup.Item 
                                action 
                                onClick={() => {
                                    console.log('Tela de cadastro de usuário selecionada');  // Verifica se o clique está funcionando
                                    onSelectScreen('cadastrousuariopanel');
                                }}
                            >
                                Usuários
                            </ListGroup.Item>
                        </ListGroup>                
                    </>
                )}  

                <h6 className="mt-4">Indicadores</h6>
                <ListGroup variant="flush">
                    {indicators.length > 0 ? (
                        indicators.map(indicator => (
                            <ListGroup.Item 
                                key={indicator.id} 
                                action 
                                onClick={() => {
                                    console.log('Indicador selecionado:', indicator.name);  // Verifica se o clique está funcionando
                                    onSelectIndicator(indicator.name.toLowerCase());
                                }}
                            >
                                {indicator.descricao}
                            </ListGroup.Item>
                        ))
                    ) : (
                        <ListGroup.Item>Nenhum indicador disponível</ListGroup.Item>
                    )}
                </ListGroup>                

              
            </Card.Body>
        </Card>
    );
}

export default Sidebar;
