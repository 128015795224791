import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../services/api';
import { Container, Row, Col, Card } from 'react-bootstrap'; 
import IndicatorPanel from './indicators/IndicatorPanel';
import Sidebar from './Sidebar';
// Indicadores
import FaturamentoPanel from './indicators/FaturamentoPanel';
import ProspeccaoPanel from './indicators/ProspeccaoPanel';
import ClientesInativosPanel from './indicators/ClientesInativosPanel';
import VendedoresTicketMedioPanel from './indicators/BalancRepres_TicketMedioPanel';
import BalanceamentoRepres_ClienteAtivo from './indicators/BalancRepres_ClienteAtivoPanel';
import CausaReclamacoes from './indicators/CausaReclamacoes';
import ClienteAtivo from './indicators/ClientesAtivos';
import Inadimplencia from './indicators/Inadimplencia';
import IndiceTratativaQuantitativo from './indicators/IndiceTratativQuant';
import IndiceVendaAbaixoTabela from './indicators/IndiceVendaAbaixoTabela';
import Inventario from './indicators/Inventario';
import MapaCalor from './indicators/MapaCalor';
import MunicipioInativo from './indicators/MunicipiosInativos';
import PercentualMargemLucro from './indicators/PercentualMargemLucro';
import PosVendaQualitativo from './indicators/PosVendaQualit';
import PosVendaQuantitativo from './indicators/PosVendaQuant';
import ReversaoTratativas from './indicators/ReversaoTratativas';
import TempoReclamacaoVisita from './indicators/TempoReclamacaoVIsita';
import TicketMedio from './indicators/TicketMedio';
import TipoTratativa from './indicators/TiposTratativas';
import VendaProdutoSupervisor from './indicators/VendaProdutoSupervisor';
import VendaSupervisor from './indicators/VendaSupervisor';
import VisitaTecnicaQualitativo from './indicators/VisitaTecnicaQualit';
import VisitaTecnicaQuantitativo from './indicators/VisitaTecnicaQuant';
// Cadastros
import CadastroUsuarioPanel from './screens/CadastroUsuarioPanel';
import PermissaoUsuarioPanel from './screens/PermissaoUsuarioPanel';
import TopMenu from './TopMenu';

function Dashboard({ empresaName, userId, userType, onLogoff, userName }) {
    const [indicators, setIndicators] = useState([]);
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [selectedScreen, setSelectedScreen] = useState(null);
    
    const location = useLocation();  // Hook do React Router para saber a rota atual

    // Resetar o estado de seleção sempre que a rota mudar
    useEffect(() => {
        setSelectedIndicator(null);
        setSelectedScreen(null);
    }, [location.pathname]);

    // Buscar indicadores do usuário logado
    useEffect(() => {
        if (userId) {
            api.getIndicatorsByUser(userId).then(data => {
                setIndicators(data.indicators);
            });
        }
    }, [userId]);

    return (
        <Container fluid className="h-100">
            <TopMenu onLogoff={onLogoff} />

            <Row className="justify-content-center my-4">
                <Col md={12}>
                    <h1 className="text-center" style={{ color: '#001d6b', fontWeight: 'bold' }}>Painel de Gestão (KPI)</h1>
                    <h2 className="text-center">Empresa: {empresaName}</h2>
                </Col>
            </Row>

            <Row className="h-100 gx-0">
                <Col md={3} className="p-4 h-100">
                    <Card className="h-100">
                        <Card.Body>
                            {/* Aqui está a saudação adicionada */}
                            <h5 style={{ color: '#001d6b', marginBottom: '20px' }}>
                                Bem Vindo(a), {userName}!
                            </h5>

                            <Sidebar 
                                indicators={indicators}
                                onSelectIndicator={(indicator) => {
                                    setSelectedScreen(null);  // Resetar tela ao selecionar indicador
                                    setSelectedIndicator(indicator);  // Seleciona indicador
                                }}
                                onSelectScreen={(screen) => {
                                    setSelectedIndicator(null);  // Resetar indicador ao selecionar tela
                                    setSelectedScreen(screen);  // Seleciona a tela de cadastro
                                }}
                                userType={userType}
                            />
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={9} className="p-4 h-100">
                    <Card className="h-100">
                        <Card.Body>
                            {/* Renderizar telas do sistema */}
                            {selectedScreen && (
                                selectedScreen === 'cadastrousuariopanel' ? (
                                    <CadastroUsuarioPanel />
                                ) : selectedScreen === 'permissaousuariopanel' ? (
                                    <PermissaoUsuarioPanel />
                                ) : (
                                    <p>Outra tela do sistema selecionada.</p>
                                )
                            )}

                            {/* Renderizar indicadores */}
                            {!selectedScreen && selectedIndicator && (
                                selectedIndicator === 'balancrepres_ticketmedio' ? (
                                    <VendedoresTicketMedioPanel />   
                                ) : selectedIndicator === 'clientesinativos' ? (
                                    <ClientesInativosPanel />                                                                    
                                ) : selectedIndicator === 'faturamento' ? (
                                    <FaturamentoPanel />
                                ) : selectedIndicator === 'prospeccao' ? (
                                    <ProspeccaoPanel /> 
                                ) : selectedIndicator === 'balanrepres_clienteativo' ? (
                                    <BalanceamentoRepres_ClienteAtivo />   
                                ) : selectedIndicator === 'causareclamacoes' ? (
                                    <CausaReclamacoes />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'clientesativos' ? (
                                    <ClienteAtivo />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'inadimplencia' ? (
                                    <Inadimplencia />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'indicetratativaquant' ? (
                                    <IndiceTratativaQuantitativo />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'indicevendaabaixotabela' ? (
                                    <IndiceVendaAbaixoTabela />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'inventario' ? (
                                    <Inventario />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'mapacalor' ? (
                                    <MapaCalor />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'municipioinativo' ? (
                                    <MunicipioInativo />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'percentualmargemlucro' ? (
                                    <PercentualMargemLucro />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'posvendaqualitativo' ? (
                                    <PosVendaQualitativo /> 
                                ) : selectedIndicator === 'posvendaquantitativo' ? (
                                    <PosVendaQuantitativo />                                                                                                                                                                                                                                                                            
                                ) : selectedIndicator === 'reversaotratativas' ? (
                                    <ReversaoTratativas />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'temporeclamacaovisita' ? (
                                    <TempoReclamacaoVisita />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'ticketmedio' ? (
                                    <TicketMedio />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'tipotratativa' ? (
                                    <TipoTratativa />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'vendaprodutosupervisor' ? (
                                    <VendaProdutoSupervisor />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'vendasupervisor' ? (
                                    <VendaSupervisor />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'visitatecnicaqualit' ? (
                                    <VisitaTecnicaQualitativo />                                                                                                                                                                                                                                   
                                ) : selectedIndicator === 'visitatecnicaqualit' ? (
                                    <VisitaTecnicaQuantitativo />                                                                                                                                                                                                                                   
                                                                                                                                                                                                                             

                                ) : (
                                    <IndicatorPanel indicator={indicators.find(indicator => indicator.id === selectedIndicator)} />
                                )
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;