import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import api from '../../services/api';

function FaturamentoPanel() {
    const [safra, setSafra] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [representante, setRepresentante] = useState('');
    const [safras, setSafras] = useState([]);
    const [supervisores, setSupervisores] = useState([]);
    const [representantes, setRepresentantes] = useState([]);
    const [faturamento, setFaturamento] = useState({ total_faturamento: 0, graph_url: null });

    useEffect(() => {
        // Carregar safras, supervisores e representantes do backend
        api.getSafras().then(data => setSafras(data.safras));
        api.getSupervisores().then(data => setSupervisores(data.supervisores));
        api.getRepresentantes().then(data => setRepresentantes(data.representantes));
    }, []);

    const fetchFaturamento = async () => {
        const filters = { safra, supervisor, representante, status: "FATURADO" };
        const data = await api.getFaturamento(filters);
        setFaturamento(data);
    };

    useEffect(() => {
        fetchFaturamento();
    }, [safra, supervisor, representante]);

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5" style={{ backgroundColor: '#001d6b', color: 'white' }}>Faturamento</Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formSafra">
                            <Form.Label>Safra</Form.Label>
                            <Form.Control
                                as="select"
                                value={safra}
                                onChange={e => setSafra(e.target.value)}
                            >
                                <option value="">Selecione a Safra</option>
                                {safras.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="formRepresentante">
                            <Form.Label>Representante</Form.Label>
                            <Form.Control
                                as="select"
                                value={representante}
                                onChange={e => setRepresentante(e.target.value)}
                            >
                                <option value="">Selecione o Representante</option>
                                {representantes.map(r => (
                                    <option key={r} value={r}>{r}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <h5>Total Faturamento: {faturamento.total_faturamento}</h5>

                {faturamento.graph_url && (
                    <div className="faturamento-chart mt-4">
                        <img src={`data:image/png;base64,${faturamento.graph_url}`} alt="Faturamento por Safra" className="img-fluid" />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}

export default FaturamentoPanel;
